<template>
  <div>

  </div>

</template>

<script>
import config from '../../utils/config'
import {listenMsg} from '../../utils/message'

export default {
    mounted(){
      this.$loading({
        lock: true,
        text: '加载中',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      window.blur()
      listenMsg((data)=>{
        if(data.type.type=='success'){
          window.location.href=config.adminhref+'#/wel/index'
          window.focus();
        }else {
          window.close()
        }
      })
    }
}
</script>

<style lang="scss" scoped>

</style>
